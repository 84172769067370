/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-14 11:23:46
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询备用金列表
export function listImprest (query) {
  return request({
    url: '/internal/recyclable/imprest/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询备用金详细
export function getImprest (id) {
  return request({
    url: '/internal/recyclable/imprest/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增备用金
export function addImprest (data) {
  return request({
    url: '/internal/recyclable/imprest',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改备用金
export function updateImprest (data) {
  return request({
    url: '/internal/recyclable/imprest',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除备用金
export function delImprest (id) {
  return request({
    url: '/internal/recyclable/imprest/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出备用金
export function exportImprest (query) {
  return request({
    url: '/internal/recyclable/imprest/export',
    baseURL,
    method: 'get',
    params: query
  })
}
