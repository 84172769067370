<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="所属工作人员" prop="postStationUserId">
        <!-- <a-input v-model="form.postStationUserId" placeholder="请输入所属工作人员" /> -->
        <a-select
          show-search
          :disabled="onlyRead"
          allowClear
          placeholder="请输入所属工作人员名称模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleUserNameSearch"
          @change="handleUserNameChange"
          :loading="loading"
          v-model="form.postStationUserId"
        >
          <a-select-option v-for="d in usersList" :key="d.userId" :value="d.userId">
            {{ d.userName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="金额" prop="amount">
        <a-input v-model="form.amount" placeholder="请输入金额 领取时正值，归还时负值" />
      </a-form-model-item>
      <a-form-model-item label="类别" prop="type">
        <a-select placeholder="请选择类别" v-model="form.type">
          <a-select-option v-for="(item, index) in typeOptions" :key="index" :value="item.dictValue">{{
            item.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="当前备用金余额"
        v-if="form.imprestBalance !== null && form.imprestBalance !== undefined"
        prop="imprestBalance"
      >
        <!-- <a-input v-model="form.imprestBalance" placeholder="请输入当前备用金余额" /> -->
        {{ form.imprestBalance }}
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-textarea v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否删除 0正常1删除" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button value="">请选择字典生成</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="saveLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getImprest, addImprest, updateImprest } from '@/api/recyclable/imprest'
import { listWorkers } from '@/api/recyclable/users'
import debounce from 'lodash/debounce'

export default {
  name: 'CreateForm',
  props: {
    optionsFormat: {
      type: Function,
      default: () => () => {}
    },
    typeOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    this.handleUserNameSearch = debounce(this.handleUserNameSearch, 500)
    return {
      loading: false,
      saveLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        postStationUserId: undefined,
        amount: null,
        type: null,
        imprestBalance: null,
        backendUserId: null,
        userName: undefined,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        postStationUserId: [{ required: true, message: '所属工作人员不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '金额不能为空', trigger: 'blur' }],
        type: [{ required: true, message: '类别不能为空', trigger: 'change' }]
      },
      usersList: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    getListUsers(name) {
      listWorkers({
        userName: name,
        type: '1'
      }).then(response => {
        this.usersList = response.data
        this.loading = false
      })
    },
    handleUserNameSearch(value) {
      console.log('handleUserNameSearch', value)
      this.loading = true
      this.getListUsers(value)
    },
    handleUserNameChange(value) {
      console.log('handleUserNameChange', value)
      this.form.postStationUserId = value
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        postStationUserId: undefined,
        amount: null,
        type: null,
        imprestBalance: null,
        userName: undefined,
        backendUserId: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getImprest(id).then(response => {
        this.form = response.data
        this.form.type = this.form.type + ''
        //  for (const key in this.form) {
        //   if (typeof this.form[key] === 'number') {
        //     this.form[key] = this.form[key] + ''
        //   }
        // }
        this.getListUsers(this.form.userName)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateImprest(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addImprest(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
