var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "所属工作人员", prop: "postStationUserId" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    disabled: _vm.onlyRead,
                    allowClear: "",
                    placeholder: "请输入所属工作人员名称模糊查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleUserNameSearch,
                    change: _vm.handleUserNameChange,
                  },
                  model: {
                    value: _vm.form.postStationUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "postStationUserId", $$v)
                    },
                    expression: "form.postStationUserId",
                  },
                },
                _vm._l(_vm.usersList, function (d) {
                  return _c(
                    "a-select-option",
                    { key: d.userId, attrs: { value: d.userId } },
                    [_vm._v(" " + _vm._s(d.userName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "金额", prop: "amount" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入金额 领取时正值，归还时负值" },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "类别", prop: "type" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择类别" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeOptions, function (item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.dictValue } },
                    [_vm._v(_vm._s(item.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.imprestBalance !== null &&
          _vm.form.imprestBalance !== undefined
            ? _c(
                "a-form-model-item",
                { attrs: { label: "当前备用金余额", prop: "imprestBalance" } },
                [_vm._v(" " + _vm._s(_vm.form.imprestBalance) + " ")]
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-textarea", {
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.saveLoading },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }